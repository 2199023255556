import React, {Component} from 'react';
import objectFitImages from 'object-fit-images';


class FeedbackItem extends Component {
  componentDidMount() {
    objectFitImages('.feedback_user_avatar img');
  }

  render() {
    return (
      <div className="feedback_item_wrap">
        <div className="feedback_item">
          <div className="feedback_text">{this.props.comment || ''}</div>
          <div className="feedback_user_info">
            <div className="feedback_user_avatar_wrap">
              <div className="feedback_user_avatar">
                <img className="lazy" data-src={this.props.avatar} alt=""/>
              </div>
            </div>
            <div className="feedback_user_block">
              <div
                className="feedback_user_name"
                dangerouslySetInnerHTML={{__html: this.props.name || ''}}
              ></div>
              <div className="feedback_user_region_wrap">
                <div className="feedback_user_region">{this.props.regionIp}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default FeedbackItem;
