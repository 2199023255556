import React, {Component} from 'react';
import utilIsNumber from 'lodash/isNumber';
import Button from "../FormElements/Button";
import RangeCash from "../FormElements/RangeCash";
import RangeMonth from "../FormElements/RangeMonth";
import wNumb from "wnumb/wNumb";
import classNames from "classnames/bind";
import {actionsStepTwoUlr} from "../../constants/api";
import {sendGet} from "../../services/ajax";
import {declOfNum} from "../../constants/numbers";
import {declOfNumMonthsArray, declOfNumYearsArray} from "../../constants/array";

const planned_price_range_min = 200000;
const planned_price_range_max = 2500000;
const formatNumber = wNumb({
  thousand: '\u2009'
});

class FormStepTwo extends Component {
  constructor(props) {
    super(props);
    const formData = props.formData;
    const state = {
      planned_price: formData.planned_price || planned_price_range_min,
      firstPayment: formData.firstPayment || 0,
      month: formData.month || 60,
      rate: formData.rate || 5,
      monthlyPayment: formData.monthlyPayment || 0,
      credit_summ: formData.credit_summ || 0,
      showMotivate: false,
      showBtnLoad: false,
      motivationActions: ''
    };
    this.state = state;
    state['monthlyPayment'] = this.calculatePay();
    state['credit_summ'] = this.state.planned_price - this.state.firstPayment;
  };

  componentDidMount() {
    this.loadActionsStep();
  }

  loadActionsStep() {
    (async () => {
      let response;
      const url = actionsStepTwoUlr;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        motivationActions: response.data.text
      });
    })();
  }

  handleCompareState = () => {
    const formData = this.props.formData;
    const state = this.state;
    if (utilIsNumber(formData.planned_price) && (state.planned_price !== formData.planned_price)) {
      this.props.handleClearStepThree();
    } else if (utilIsNumber(formData.firstPayment) && (state.firstPayment !== formData.firstPayment)) {
      this.props.handleClearStepThree();
    } else if (utilIsNumber(formData.month) && (state.month !== formData.month)) {
      this.props.handleClearStepThree();
    }
  };

  handleOnSlidePlannedPrice = (value) => {
    this.setState({planned_price: value});
    this.recalculateAndUpdateMonthlyPay();
  };

  handleOnSlideOwnFunds = (value) => {
    this.setState({firstPayment: value});
    this.recalculateAndUpdateMonthlyPay();
  };

  handleOnSlideTerm = (value) => {
    this.setState({month: value});
    this.recalculateAndUpdateMonthlyPay();
  };

  calculatePay = () => {
    const interestRate = this.state.rate;
    let term = this.state.month;
    let carPrice = this.state.planned_price;
    // let initialPay = this.state.planned_price * this.state.firstPayment / 100;
    let initialPay = this.state.firstPayment;
    return this.calculateMonthly(initialPay, carPrice, term, interestRate);
  };

  recalculateAndUpdateMonthlyPay = () => {
    this.setState({
      monthlyPayment: this.calculatePay(),
      // credit_summ: this.state.planned_price - this.state.planned_price * this.state.firstPayment / 100
      credit_summ: this.state.planned_price - this.state.firstPayment
    });
  };

  //Credit calculat
  calculateMonthly = (firspay, price, months, persent) => {
    if (firspay > price) {
      return 0;
    }
    let pmt = Math.ceil(
      ((persent / 1200) *
        (price - parseInt(firspay)) *
        -1 *
        Math.pow(1 + persent / 1200, months)) /
      (1 - Math.pow(1 + persent / 1200, months))
    );
    // возвращает pmt на рубль меньше, из за того что формулы на виджете и лендинге не совпадают.
    return pmt;
  };

  handleSubmit = (data) => {
    this.handleCompareState();
    if (!this.state.motivationActions) {
      this.props.onSubmit({...this.state, monthFormatted: this.formattedDate(this.state.month)});
      setTimeout(() => {
        if(typeof window.yaCounter56850232 !== 'undefined') {
          window.yaCounter56850232.reachGoal("oprbud");
        }
        if(typeof window.gtag !== 'undefined') {
          window.gtag('config', 'UA-155061629-1', {'page_path': '/oprbud'});
        }
        this.props.changeStep(2);
      }, 1);
    } else {
      setTimeout(() => {
        if(typeof window.yaCounter56850232 !== 'undefined') {
          window.yaCounter56850232.reachGoal("oprbud");
        }
        if(typeof window.gtag !== 'undefined') {
          window.gtag('config', 'UA-155061629-1', {'page_path': '/oprbud'});
        }
        this.props.changeStep(2);
      }, 2500);

      this.setState({showMotivate: true, showBtnLoad: true}, () => {
        setTimeout(() => {
          this.setState({showMotivate: false});
        }, 300);
      });
      setTimeout(() => {
        this.props.onSubmit({...this.state, monthFormatted: this.formattedDate(this.state.month)});
      }, 1);
    }
  };

  handleChangedStepBack = (data) => {
    this.props.onSubmit({...this.state, monthFormatted: this.formattedDate(this.state.month)});
    this.props.changeStep(0);
  };


  formattedDate(date) {
    const rangeValue = parseInt(date, 10);
    const fullYears = parseInt(rangeValue / 12, 10);
    const remainingMonths = parseInt(rangeValue - fullYears * 12, 10);
    let termValue =
      fullYears > 0
        ? fullYears + ' ' + declOfNum(fullYears, declOfNumYearsArray)
        : '';
    if (remainingMonths > 0) {
      if (fullYears > 0) {
        termValue += ' ';
      }
      termValue +=
        '' +
        remainingMonths +
        ' ' +
        declOfNum(remainingMonths, declOfNumMonthsArray);
    }
    return termValue;
  }

  render() {
    const ownFundsValue = this.state.planned_price * 70 / 100;
    const motivateCls = classNames('motivation_info', {'active': this.state.showMotivate});
    const clsBtnStep = classNames('icon', {'load': this.state.showBtnLoad && this.state.motivationActions});

    return (
      <div className='form_content_step'>
        <div className="form_row_range_wrap">
          <div className="form_row_range">
            <RangeCash
              defaultStart={this.state.planned_price}
              rangeMin={planned_price_range_min}
              rangeMax={planned_price_range_max}
              step={10000}
              title='Планируемая стоимость авто'
              onSlide={this.handleOnSlidePlannedPrice}
              recalc={this.recalculateAndUpdateMonthlyPay}
              formatterInput={() => {
                return formatNumber.to(this.state.planned_price)
              }}
            />
            <div className="range_slider_info">
              <span>200 000 &#x20bd;</span>
              <span>2 500 000 &#x20bd;</span>
            </div>
          </div>
          <div className="form_row_range">
            <RangeCash
              defaultStart={this.state.firstPayment}
              rangeMin={0}
              rangeMax={ownFundsValue}
              step={5000}
              formatterInput={(value) => {
                return formatNumber.to(parseInt(value, 10));
              }}
              title='Собственные средства на покупку'
              onSlide={this.handleOnSlideOwnFunds}
            />
            <div className="range_slider_info">
              <span>0 &#x20bd;</span>
              {/*<span>{formatNumber.to(this.state.planned_price * ownFundsValue / 100)}&thinsp;&#x20bd;</span>*/}
              <span>{formatNumber.to(ownFundsValue)}&thinsp;&#x20bd;</span>
            </div>
          </div>
          <div className="form_row_range">
            <RangeMonth
              defaultStart={this.state.month}
              rangeMinMonth={6}
              rangeMaxMonth={84}
              title='Срок кредита'
              value={this.state.month}
              onSlide={this.handleOnSlideTerm}
            />
            <div className="range_slider_info">
              <span>6 месяцев</span>
              <span>7 лет</span>
            </div>
          </div>
          <div className="form_payment">
            <div className="form_payment_title">Ежемесячный платёж</div>
            <div className="form_payment_money">{formatNumber.to(this.state.monthlyPayment)}&thinsp;&#x20bd;</div>
          </div>
        </div>
        <div className="form_content_step_bottom step_two_bottom">
          <div className="form_content_step_bottom_left">
            <div className="btn_back_step hide_mobile" onClick={this.handleChangedStepBack}>
              <span>К предыдущему шагу</span>
            </div>
          </div>
          <div className="form_content_step_bottom_right">
            <div className="form_content_step_bottom_center">
              <div className="form_content_step_bottom_center_title">Сумма кредита</div>
              <div
                className="form_content_step_bottom_center_credit_amount">{formatNumber.to(this.state.credit_summ)}&thinsp;&#x20bd;</div>
            </div>
            <div className="motivation_wrap hide_mobile">
              <div className={motivateCls}>{this.state.motivationActions}</div>
              <Button text='Далее' cls={clsBtnStep} type='submit' onClick={this.handleSubmit}/>
            </div>
          </div>
        </div>
        <div className="mobile_only">
          <div className="motivation_wrap">
            <div className={motivateCls}>{this.state.motivationActions}</div>
            <Button text='Далее' cls={clsBtnStep} type='submit' onClick={this.handleSubmit}/>
          </div>
          <div className="mobile_steps_nav">
            <div className="btn_back_step" onClick={this.handleChangedStepBack}>
              <span>К предыдущему шагу</span>
            </div>
            <div className="mobile_steps_nav_number">
              <span className='green'>Шаг&nbsp;</span>
              <span className="green">2&nbsp;</span>
              <span>из 3</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormStepTwo;
