import axios from 'axios';
import { baseUrl } from '@app-constants/api';

const httpClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
  }
});

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  httpClient.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  // console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.axios = httpClient;

export default httpClient;
