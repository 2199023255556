import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import isPlainObject from 'lodash/isPlainObject';
import {addValidationRule} from 'formsy-react';

addValidationRule('isNotEmptyString', function (values, value) {
  return !!value;
});

addValidationRule('isAboveZero', function (values, value) {
  return isNumber(value) && value > 0;
});

addValidationRule('countDigits', function (values, value, count) {
  if (isNumber(value)) {
    return value.toString().length === parseInt(count, 10);
  }

  if (isUndefined(value)) {
    return parseInt(count, 10) === 0;
  }

  return false;
});

addValidationRule('maxNumber', function (values, value, maxValue) {
  return parseInt(value, 10) <= parseInt(maxValue, 10);
});

addValidationRule('valueObjectHasNotEmptyProperty', function (values, value) {
  return !isPlainObject(value)
    ? false
    : (isUndefined(value.value) ? false : (!!value.value || value.value === 0));
});