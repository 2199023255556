import React, {Component} from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

class RangeCash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ref: null,
      value: props.defaultStart,
    };

    this.value = props.defaultStart;
    this.rangeMax = props.rangeMax;
    this.timer = null;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSlide = (render, handle, value) => {
    let fixedValue = value[0].toFixed(0);
    this.rangeMax = this.props.rangeMax;
    this.setState({ value: fixedValue }, this.updateParentValue);
  };

  updateParentValue = () => {
    if (this._isMounted) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (typeof this.props.onSlide === 'function') {
          this.props.onSlide(parseInt(this.state.value, 10));
        }
      }, 1);
    }
  };

  formatterInput = (value) => {
    return value;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this._isMounted) {
      if (this.rangeMax !== this.props.rangeMax) {
        this.updateSliderStart();
      }

      if (this.props.rangeMax < this.state.value) {
        this.setState({value: this.props.rangeMax}, this.updateParentValue);
      }
    }
  }

  updateSliderStart = () => {
    const {ref, value} = this.state;
    if (ref && ref.noUiSlider) {
      ref.noUiSlider.set(parseInt(value, 10));
    }
  };

  render() {
    const formatterInput = typeof this.props.formatterInput === 'function'
      ? this.props.formatterInput
      : this.formatterInput;
    return (
      <div className='range_slider_wrap'>
        <label>{this.props.title}</label>
        <input className='range_slider_text_val' type="text" readOnly value={'' +formatterInput(this.state.value) + '\u2009₽'}/>
        <div className="range_nouislider">
          <Nouislider
            instanceRef={instance => {
              if (instance && !this.state.ref) {
                this.setState({ ref: instance });
              }
            }}
            connect="lower"
            start={this.value}
            range={{
              min: this.props.rangeMin,
              max: this.props.rangeMax
            }}
            onSlide={this.onSlide}
            step={this.props.step}
          />
        </div>
      </div>
    );
  }
}

RangeCash.defaultProps = {
  rangeMin: 0,
  rangeMax: 10,
  defaultStart: 0,
};

export default RangeCash;
