import React, {Component} from 'react';
import Formsy from 'formsy-react';
import InputString from "../../../components/FormElements/InputString";
import Button from "../../../components/FormElements/Button";
import Checkbox from "../../../components/FormElements/Checkbox";
import classNames from 'classnames/bind';
import InputPhoneMask from "../../FormElements/InputPhoneMask";

class FormStepThreeContacts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isChecked: true,
      fio: props.fieldFioValue || '',
      phone: props.fieldPhoneValue || ''
    };
    this.fromRef = React.createRef();
  }

  submit = (model) => {
    this.props.handleOnSubmit(model);
    document.querySelector('body').classList.add('overflow');
  };

  handleIsChecked = (value) => {
    this.setState({isChecked: value})
  };

  handleChange = (model) => {
    this.props.handleChangeFields(model);
  };

  handleClearValidate = (model) => {
    if (!model.fio && !model.phone) {
      setTimeout(() => {
        this.fromRef.current.reset();
      }, 1000);
    }
  };

  render() {
    const clsInputs = classNames('credit_form_content', {'hint_inputs': this.props.hintClass});
    return (
      <React.Fragment>
        <div className="form_subtitle">Оставь контакты, и мы расскажем о каждом предложении</div>
        <Formsy className="credit_form" onChange={this.handleChange} onValidSubmit={this.submit}
                onInvalidSubmit={this.handleClearValidate} ref={this.fromRef}>
          <div className={clsInputs}>
            <InputString label='Фамилия Имя' name='fio'
                         defaultValue={this.state.fio}
                         formatter={/[^a-zA-Zа-яА-ЯёЁ]{2,}\s+[^a-zA-Zа-яА-ЯёЁ]{2,}\s?/g}
                         validations={{
                           matchRegexp: /^[а-яА-ЯёЁ]{2,}\s+[а-яА-ЯёЁ]{1,}[а-яА-ЯёЁ\s]+$/,
                           minLength: 2
                         }}
                         validationErrors={{
                           matchRegexp: 'Заполни Фамилию и Имя'
                         }}
                         required/>
            <InputPhoneMask label='Телефон' name='phone' defaultValue={this.state.phone} validations={{
              matchRegexp: /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/,
              isLength: 18
            }} required/>
            <Button text='Отправить' cls='icon' type='submit' disabled={!this.state.isChecked || !this.props.validBirthDay}/>
            <Checkbox isChecked={this.handleIsChecked}/>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

export default FormStepThreeContacts;
