import React from 'react';
import InputMask from 'react-input-mask';
import {beforeMaskedValueChange} from '../../constants/formatterBirthday.js';

class InputDate extends React.Component {
  constructor(props) {
    super(props);
    this.isChanged = false;
    this.isFullFill = false;
    this.beforeMaskedValueChange = beforeMaskedValueChange.bind(this);
  }

  changeValue(event) {
    const newValue = event.currentTarget.value;
    if (typeof this.props.handlerOnChange === 'function') {
      this.props.handlerOnChange(newValue);
    }

    this.isChanged = true;

    if (newValue.length > 9) {
      this.isFullFill = true;
    }
  }

  render() {
    let className = 'form_row';
    if (this.isChanged && !this.props.validBirthDay && this.isFullFill) {
      className = [className, 'error'].join(' ');
    } else if (this.isChanged && this.props.validBirthDay && this.isFullFill) {
      className = [className, 'valid'].join(' ');
    }

    return (

      <div className={className}>
        <div style={{'position': 'relative'}}>
          <InputMask
            onChange={(event) => this.changeValue(event)}
            value={this.props.value || ''}
            placeholder="01.01.1970"
            mask="99.99.9999"
            beforeMaskedValueChange={this.beforeMaskedValueChange}
            type='tel'
            maskChar=""/>
          <label>{this.props.label}</label>
        </div>
        <div className='error_text'>* Кредит не выдается лицам младше 18 и старше 65 лет.</div>
      </div>
    );
  }
}

export default InputDate;
