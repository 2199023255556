import React, {Component} from "react";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.circleProgress = React.createRef();
    this.textProgress = React.createRef();
  }

  componentDidMount() {
    // math trick 2*pi*57 = 358, must be less than 360 degree
    let circle = this.circleProgress.current;
    let myTimer = this.textProgress.current;
    let interval = 30;
    let angle = 0;
    let angle_increment = 6;

    window.timer = window.setInterval(() => {
      circle.setAttribute("stroke-dasharray", angle + ", 20000");
      myTimer.innerHTML = parseInt(angle / 360 * 100) + '%';

      if (angle >= 360) {
        window.clearInterval(window.timer);
        this.props.onFinish();
      }
      angle += angle_increment;
    }, interval);
  }

  render() {

    return (
      <div className='loan_loader_wrap'>
        <svg  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
             viewBox="0 0 130 130" preserveAspectRatio="none" style={{width: 130, height: 130, top: 0, left: 0}}>
          <circle ref={this.circleProgress} cx="100" cy="100" r="57" id="green-halo" fill="none" stroke="#4AC054" strokeWidth="10"
                  strokeDasharray="0,20000" transform="rotate(-90,66,100)"/>
          <text ref={this.textProgress} id="myTimer" textAnchor="middle" x="66" y="74" style={{fontSize: 20, fill: '#4AC054', fontWeight: 500}}>0%</text>
        </svg>

        <div className="loan_loader_text">Идёт подбор кредитных предложений по вашим критериям</div>
      </div>
    )
  }
};

export default Loader;
