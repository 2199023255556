import { withFormsy } from 'formsy-react';
import React from 'react';
import {bind_phone_mask} from '../../constants/phoneMask';

class InputPhoneMask extends React.Component {
  constructor(props) {
    super(props);
    this.isChanged = false;
    this.input = React.createRef();
  }

  componentDidMount() {
    this.props.setValue(this.props.defaultValue);
    bind_phone_mask(this.input.current);
  }

  handleOnChange = (event) => {
    let newValue = event.currentTarget.value;

    if (typeof this.props.handlerOnChange === 'function') {
      this.props.handlerOnChange(newValue);
    }
    if (newValue.replace(/[^0-9]+/g, "").length > 10) {
      this.isChanged = true;
    }

    this.props.setValue(newValue);
  };

  render() {
    let className = 'form_row row_white input_phone';
    if (
      ((this.isChanged && this.props.isFormSubmitted()) || this.props.isFormSubmitted())
      && !this.props.isValid()
    ) {
      className = [className, 'error'].join(' ');
    } else if (
      ((this.isChanged && this.props.isFormSubmitted()) || this.props.isFormSubmitted())
      && this.props.isValid()
    ) {
      className = [className, 'valid'].join(' ');
    }
    const value = this.props.getValue() || '';

    return (
      <div className={className}>
        <input type="tel" ref={this.input} onChange={this.handleOnChange} placeholder="&nbsp;" value={value}/>
        <label>{this.props.label}</label>
      </div>

    );
  }
}

export default withFormsy(InputPhoneMask);
