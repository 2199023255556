import React from "react";
import classNames from 'classnames/bind';

const LoanItem = props => {
  const cls = classNames( 'loan_offers_item', {'active': props.active});
  return (
    <div className={cls} onClick={() => props.handleClick(props.id)}>
      <div className="loan_offers_item_logo_wrap">
        <div className="logo_offers_item_logo">
          <img src={props.image} alt=""/>
        </div>
      </div>
      <div className="loan_offers_item_info">
        <div className="loan_offers_item_info_month">{props.term}</div>
        <div className="loan_offers_item_info_rate">ставка {props.rate}</div>
      </div>
    </div>
  );
};

export default LoanItem;
