import React from 'react';
import Ripples from 'react-ripples';

const Button = props => {
  const cls = [
    'btn_green',
    props.cls
  ];

  function handleOnClick() {
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  }

  return (
    <div className='form_row'>
      { props.disabled
        ?
        <button className={cls.join(' ')} type={props.type} onClick={handleOnClick} disabled={props.disabled}>
            <span>{props.text}</span>
        </button>
        :
        <Ripples>
          <button className={cls.join(' ')} type={props.type} onClick={handleOnClick}>
            <span>{props.text}</span>
          </button>
        </Ripples>
      }


    </div>
  )
};

export default Button;
