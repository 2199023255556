import React, {Component} from 'react';

class BankItem extends Component  {

  render() {
    const clsBank = ['bank_logo', this.props.bank_class];

    return (
      <div className="bank_item_wrap">
        <div className="bank_item">
          <div className="bank_logo_wrap">
            <div className={clsBank.join(' ')}>
              <img className="lazy" data-src={this.props.icon} alt=""/>
            </div>
          </div>
          <div className="bank_name" dangerouslySetInnerHTML={{__html: this.props.name || ''}}></div>
        </div>
      </div>
    );
  }
};

export default BankItem;
