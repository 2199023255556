import React, {Component} from "react";
import LoanItem from "../../LoanItem/LoanItem";
import {declOfOffersArray} from '../../../constants/array';
import {declOfNum} from "../../../constants/numbers";

class LoanOffers extends Component {

  render() {
    return (
      <div className='loan_offers_wrap'>
        <div className="form_subtitle_loans">
          Найдено {this.props.countOffers} кредитных {declOfNum(this.props.countOffers, declOfOffersArray)}
        </div>
        <div className="loan_offers_list">
          {this.renderList()}
        </div>
      </div>
    )
  }

  renderList() {
    const sentencesList = (this.props.sentencesList || []).slice(0, this.props.countOffers);
    const sentenceItems = sentencesList.map(({image, rate, term, id}, index) => {
      return (
        <LoanItem
          key={index}
          image={image}
          rate={rate}
          term={term}
          id={id}
          active={this.props.selectedLoan === id}
          handleClick={this.props.handlerChangeLoan}
        />
      )
    });

    if (sentenceItems.length > 0) {
      return (
        <React.Fragment>
          {sentenceItems}
        </React.Fragment>
      );
    }
    return <div/>;
  }
}


export default LoanOffers;
