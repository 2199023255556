import React, {Component} from "react";
import Nouislider from "nouislider-react";
import {declOfNumMonthsArray, declOfNumYearsArray} from '../../constants/array';
import {declOfNum} from '../../constants/numbers';
import "nouislider/distribute/nouislider.css";

class RangeMonth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultStart,
    };

    this.value = props.defaultStart;
    this.timer = null;
  }

  onSlide = (render, handle, value, un, percent) => {
    let fixedValue = value[0].toFixed(0);
    this.setState({ value: fixedValue }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (typeof this.props.onSlide === 'function') {
          this.props.onSlide(parseInt(this.state.value, 10));
        }
      }, 1);
    });
  };

  render() {
    return (
      <div className='range_slider_wrap'>
        <label>{this.props.title}</label>
        <input className='range_slider_text_val' id='planned_price' readOnly type="text" value={this.formattedDate()}/>
        <div className="range_nouislider">
          <Nouislider
            connect="lower"
            start={this.value}
            range={{
              min: this.props.rangeMinMonth,
              max: this.props.rangeMaxMonth
            }}
            onSlide={this.onSlide}
          />
        </div>
      </div>
    );
  }

  formattedDate() {
    const rangeValue = parseInt(this.props.value, 10);
    const fullYears = parseInt(rangeValue / 12, 10);
    const remainingMonths = parseInt(rangeValue - fullYears * 12, 10);
    let termValue =
      fullYears > 0
        ? fullYears + ' ' + declOfNum(fullYears, declOfNumYearsArray)
        : '';
    if (remainingMonths > 0) {
      if (fullYears > 0) {
        termValue += ' ';
      }
      termValue +=
        '' +
        remainingMonths +
        ' ' +
        declOfNum(remainingMonths, declOfNumMonthsArray);
    }
    return termValue;
  }
}

export default RangeMonth;
