import React from 'react';
import logo from '../../assets/images/logo.svg';
import HeaderInfo from '../../components/HeaderInfo/HeaderInfo';

const Header = props => {
  const filteredPhone = (props.phone || "").replace(/[^0-9+]/ig, "");
  return (
    <header>
      <div className="header_wrap">
        <div className="header container">
          <div className="logo_wrap">
            <div className="logo">
              <img src={logo} alt=""/>
            </div>
          </div>
          <div className="header_text">
            {props.headerText.header_text}
          </div>
          {props.phone && props.phone !== ''
            ? <div className="header_phone">
              <a href={'tel:' + filteredPhone}>
                <span className='not_mobile'>{props.phone}</span>
                <span className='mobile_only with_icon'>Позвонить</span>
              </a>
            </div>
            : null
          }
        </div>
        <HeaderInfo slonStepsInfo={props.headerText}/>
      </div>
    </header>
  );
};

export default Header;
