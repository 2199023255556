import Inputmask from "inputmask";

export function bind_phone_mask(inputEl, regexMask, placeholder) {
  if (regexMask == null) {
    regexMask = '^\\+7 \\([3489]\\d\\d\\) \\d\\d\\d-\\d\\d-\\d\\d$';
  }
  if (placeholder == null) {
    placeholder = '+7 (___) ___-__-__';
  }

  Inputmask({
    regex: regexMask,
    placeholder: placeholder,
    // showMaskOnHover: false,
    postValidation: function(buffer, pos, currentResult, opts) {
      let nums = buffer.join('').replace(/[^0-9]+/g, '');
      let hasSevenNumbersInARow = (/(\d)\1{6}/g).test(nums);
      return !hasSevenNumbersInARow;
    }
  }).mask(inputEl);
  inputEl.addEventListener('input', function(event) {
    let val = this.value;
    if (val.replace(/[^0-9]+/g, '') === '789') {
      this.value = '79';
    }
  });
}

export function validate_phone(value, regex) {
  if (value == null) {
    value = '';
  }
  if (regex == null) {
    regex = /^\+7 \([3489]\d\d\) \d\d\d \d\d \d\d$/g;
  }
  let nums = value.replace(/[^0-9]+/g, '');
  return regex.test(value) && !(/(\d)\1{6}/g).test(nums);
}
