import React, {Component} from 'react';

class HeaderInfoItem extends Component {

  render() {
    const cls = [
      'header_info_item',
      this.props.item_number
    ];
    return (
      <div className={cls.join(' ')}>
        <div className="header_info_item_img_wrap">
          <div className="header_info_item_img">
            <picture>
              <source srcset={this.props.iconwebp} type="image/webp"/>
              <img src={this.props.icon} alt=""/>
            </picture>
          </div>
        </div>
        <div className="header_info_item_number">{this.props.number}</div>
        <div className="header_info_item_text" dangerouslySetInnerHTML={{__html: this.props.text}}></div>
      </div>
    );
  }
};

export default HeaderInfoItem;
