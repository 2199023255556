import React, {Component} from 'react';
import FeedbackItem from '../../components/FeedbackItem/FeedbackItem'
import {feedbacksUrl} from '@app-constants/api';
import {sendGet} from '@app-services/ajax';
// eslint-disable-next-line
var yall = require('script-loader!yall-js/dist/yall.min.js');

class Feedback extends Component {
  state = {
    feedbackList: []
  };

  componentDidMount() {
    this.setState({feedbackList: this.renderListFeedbacks()});
  }

  componentDidUpdate() {
    if (Array.isArray(this.state.feedbackList) && this.state.feedbackList.length) {
      window.yall();
    }
  }

  render() {
    return (
      <section className="section_feedback container">
        <h1>{this.props.headerText.feedback_header}</h1>
        {this.renderList()}
      </section>
    )
  }

  renderList() {
    const feedbackItems = (this.state.feedbackList || []).map(({image, text, name, region_id}, index) => {
      return (
        <FeedbackItem
          key={index}
          avatar={image}
          comment={text}
          name={name}
          regionIp={index === 0 ? (this.props.regionIp || '') : region_id}
        />
      )
    });

    if (feedbackItems.length > 0) {
      return (
        <div className="feedback_list_wrap">
          <div className="feedback_list">
            {feedbackItems}
          </div>
        </div>
      );
    }
    return <div/>;
  }

  renderListFeedbacks() {
    (async () => {
      let responseList;
      const url = feedbacksUrl;

      try {
        responseList = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        feedbackList: responseList.data,
      });
    })();
  }
}


export default Feedback;
