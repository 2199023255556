import qs from 'qs';
import httpClient from '@app-utils/http-client';

export const sendGet = function (url, data = {}) {
  return httpClient.get(url, { params: data });
};

export const sendPost = function (url, data = {}) {
  return httpClient.post(url, qs.stringify(data));
};

export const sendPut = function (url, data = {}) {
  return httpClient.put(url, qs.stringify(data), { params: data });
};

export const sendDelete = function (url, data = {}) {
  return httpClient.delete(url, qs.stringify(data), { params: data });
};
