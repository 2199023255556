import React from 'react';
import { Collapse } from 'react-collapse';

const QuestionItem = (props) => {
  const {number, head, info, handleChangeOpen, isOpened, id} = props;

  return (
    <div className="question_item">
      <div className="question_head" onClick={() => handleChangeOpen(id)}>
        <span>{number > 9 ? number + 1 : '0' + (number + 1)}.</span>
        &nbsp;
        {head}
      </div>
      <Collapse isOpened={isOpened}>
        <div className="question_info_wrap">
          <div className="question_info" dangerouslySetInnerHTML={{__html: info}}/>
        </div>
      </Collapse>
    </div>
  );
};

export default QuestionItem;
