import React, {Component} from 'react';
import utilFind from 'lodash/find';
import FormStepOne from "../../components/FormStepOne/FormsStepOne";
import FormStepTwo from "../../components/FormStepTwo/FormStepTwo";
import FormStepThree from "../../components/FormStepThree/FormStepThree";
import Modal from "../../components/Modal/Modal";
import {consultationStoreUrl} from "@app-constants/api";
import {sendPost} from '@app-services/ajax';
import HeaderInfo from "../../components/HeaderInfo/HeaderInfo";
import classNames from 'classnames/bind';
import {actionUrl, brandsUrl, modelsUrl, regionsUrl} from "../../constants/api";
import {sendGet} from "../../services/ajax";
import YearItem from "../../components/YearItem/YearItem";
import scrollTo from "scroll-to-element";

function getRandomBoolean() {
  return Math.round(Math.random());
}

const years = [];
const currentDate = new Date();
const maxCountYear = 8;
const currentYear = currentDate.getFullYear() - 1;
const minYear = currentYear - maxCountYear + 1;
for (let i = minYear; i <= currentYear; ++i) {
  years.push('' + i);
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.formWrap = React.createRef();
    this.state = {
      step: 0,
      activeYear: '' + minYear,
      modalShow: false,
      countOffers: getRandomBoolean() ? 6 : 3,
      addMobileClass: false,
      years: [],
      carMarks: [],
      carModels: [],
      selectedBrand: null,
      selectedBrandName: '',
      selectedModel: null,
      selectedModelName: '',
      actionsMessage: '',
      regions: [],
      changedStep: false,
      changeClsAnimate: false,
      changeClsAnimateFullHeight: false,
      changeClsAnimateFullSecondHeight: false,
      changeClsStatic: false,
      stepThreeShowSecondContent: false,
      formData: {},
      formSended: false
    };

    this.changedStapFlag = false;
  };

  onSubmitStep = (model) => {
    setTimeout(() => {
      this.setState({formData: {...this.state.formData, ...model}});
    }, 10);
  };

  componentDidMount() {
    this.setState({years: years});
    this.loadBrands();
    this.loadRegions();
  }

  loadBrands() {
    (async () => {
      let response;
      const url = brandsUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        carMarks: response.data.map((car) => {
          return {
            label: car.name,
            value: car.id
          };
        })
      });
    })();
  }

  getCarMarkById = (id) => {
    const found = this.state.carMarks.filter((item) => {
      return parseInt(item.value) === parseInt(id);
    });
    return found.shift();
  };

  handleClearStepThree = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        birthDay: '',
        // region: null,
        fio: '',
        phone: '',
        offerId: null,
        stepThreeShowSecondContent: false
      }
    });
  };

  handleChangeModel = (value) => {
    this.setState({selectedModel: value.value, selectedModelName: value.label});
  };

  getCarModelById = (id) => {
    const found = this.state.carModels.filter((item) => {
      return parseInt(item.value) === parseInt(id);
    });
    return found.shift();
  };

  loadActions() {
    (async () => {
      let response;
      const actions = actionUrl + '/' + this.state.selectedBrand;

      try {
        response = await sendGet(actions);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        actionsMessage: response.data
      });
    })();
  }

  loadModels() {
    (async () => {
      let response;
      const url = modelsUrl;

      try {
        response = await sendGet(url + '/' + this.state.selectedBrand);
      } catch (err) {
        throw new Error(err);
      }
      this.setState({
        carModels: response.data.map((car) => {
          return {
            label: car.name,
            value: car.id
          };
        })
      });
    })();
  }

  loadRegions() {
    (async () => {
      let response;
      const url = regionsUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      const data = response.data;
      const regions = data.map((region) => {
        return {
          label: region.name,
          value: region.id
        };
      });
      const regionIp = this.props.regionIp;

      this.setState({
        formData: {
          ...this.state.formData,
          region: regionIp ? utilFind(regions, ['label', regionIp]) : null,
        },
        regions: regions
      });
    })();
  }

  handleChangeBrand = (value) => {
    this.setState({
      selectedBrand: value.value,
      selectedBrandName: value.label,
      selectedModelName: '',
      selectedModel: null
    }, () => {
      this.loadModels();
      this.loadActions();
    });
  };

  handlerChangeYear = (year) => {
    this.setState({
      activeYear: year
    });
  };

  yearsList = () => {
    const yearItems = (this.state.years || []).map((year, index) => {
      return (
        <YearItem
          key={index}
          year={year}
          active={this.state.activeYear === year}
          activeYear={this.handlerChangeYear}
        />
      )
    });

    if (yearItems.length > 0) {
      return (
        <div className="form_content_years_list">
          {yearItems}
        </div>
      );
    }
    return <div/>;
  };

  onSubmitLastStep = (model) => {
    this.setState({formData: {...this.state.formData, ...model}}, () => {
      (async () => {
        const url = consultationStoreUrl;
        const data = {
          brand_id: this.state.formData.brandId,
          model_id: this.state.formData.modelId,
          car_year: this.state.formData.year,
          car_cost: this.state.formData.planned_price,
          credit_initial_pay: this.state.formData.firstPayment,
          credit_term: this.state.formData.monthFormatted,
          credit_rate: this.state.formData.rate,
          credit_month_pay: this.state.formData.monthlyPayment,
          credit_summ: this.state.formData.credit_summ,
          age: this.state.formData.birthDay,
          phone: this.state.formData.phone,
          fio: this.state.formData.fio,
          region_id: {
            id: this.state.formData.region.value,
            title: this.state.formData.region.label
          },
          offer_id: this.state.formData.offerId,
          form_type: 'credit_form'
        };

        if(!this.state.formSended) {
          this.setState({formSended: true});
          try {
            await sendPost(url, data);
          } catch (err) {
            throw new Error(err);
          }
          if (typeof window.yaCounter56850232 !== 'undefined') {
            window.yaCounter56850232.reachGoal("nemlich");
          }
          if (window.gtag !== 'undefined') {
            window.gtag('event', 'conversion', {'send_to': 'AW-668997757/sjkSCPCylsIBEP2wgL8C'});
          }
          if (typeof window.gtag !== 'undefined') {
            window.gtag('config', 'UA-155061629-1', {'page_path': '/nemlich'});
          }
          this.setState({
            modalShow: true,
            formSended: false
          });
        }
      })();
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.matchMedia("(max-width: 759px)").matches) {
      if (this.changedStapFlag) {
        this.changedStapFlag = false;
        scrollTo('.header_wrap', {
          offset: 50,
          ease: 'inOutQuad',
          duration: 300
        });
      }
    }
  }

  handleChangeStep = (value) => {
    this.changedStapFlag = true;
    this.loadActions();
    this.setState({changedStep: true}, () => {
      setTimeout(() => {
        this.setState({step: value}, () => {
          this.setState({changedStep: false})
        });
      }, 500);
    });
  };

  hideModal = () => {
    this.setState({
      modalShow: false,
      step: 0,
      activeYear: '' + minYear,
      addMobileClass: false,
      selectedBrand: null,
      selectedModel: null,
      changeClsAnimateFullHeight: false,
      changeClsAnimateFullSecondHeight: false,
      changeClsAnimate: false,
      formData: {
        region: utilFind(this.state.regions, ['title', this.props.regionIp]),
      }
    });
    if (window.matchMedia("(max-width: 759px)").matches) {
      if (this.state.countOffers === 6) {
        scrollTo('.section_feedback', {
          offset: -100,
          ease: 'inOutQuad',
          duration: 500
        });
      }
      else {
        scrollTo('.section_feedback', {
          offset: 30,
          ease: 'inOutQuad',
          duration: 500
        });
      }
    } else {
      scrollTo('.section_feedback', {
        offset: -115,
        ease: 'inOutQuad',
        duration: 500
      });
    }
  };

  handleShowSecondContent = () => {
    this.setState({showSecondContent: true})
  };

  handleChangeMobileClass = () => {
    this.setState({
      addMobileClass: !this.state.addMobileClass
    });
  };

  changeClsAnimate = () => {
    this.setState({changeClsAnimate: true});
  };

  handleChangeClsAnimate = () => {
    this.setState({changeClsAnimate: false});
  };

  changeClsAnimateFullHeight = () => {
    this.setState({changeClsAnimateFullHeight: !this.state.changeClsAnimateFullHeight})
  };

  changeClsAnimateFullSecondHeight = () => {
    this.setState({changeClsAnimateFullSecondHeight: !this.state.changeClsAnimateFullSecondHeight})
  };

  clearClsAnimate = () => {
    this.setState({changeClsAnimate: false, changeClsAnimateFullHeight: false, changeClsAnimateFullSecondHeight: false})
  };

  changeClsStatic = () => {
    this.setState({changeClsStatic: true})
  };

  render() {
    const stepHead = [
      {number: 1, title: this.props.headerText.step_one_header},
      {number: 2, title: this.props.headerText.step_two_header},
      {number: 3, title: this.props.headerText.step_three_header}
    ];

    const clsSloanMobile = classNames('mobile_component', {'step_visible': this.state.step === 0});

    const clsHeadContent = classNames('form_content_head', {'hide_mobile': this.state.addMobileClass});

    const clsForm = classNames('form_content_info', {'changed_step': this.state.changedStep});

    const clsFormWrap = classNames('form_content',
      {'form_content_last_step': this.state.step === 2},
      {'form_content_first_step': this.state.step === 0},
      {'loader_show': this.state.changeClsAnimate},
      {'loans_content_show': this.state.countOffers === 6 && (this.state.changeClsAnimateFullHeight || this.state.formData.stepThreeShowSecondContent === true)},
      {'loans_content_show_second': this.state.countOffers === 3 && (this.state.changeClsAnimateFullSecondHeight || this.state.formData.stepThreeShowSecondContent === true)},
      {'form_content_static': this.state.step === 0 || this.state.step === 1},
    );

    return (
      <React.Fragment>

        <HeaderInfo slonStepsInfo={this.props.headerText} cls={clsSloanMobile}/>
        <section className="section_form container" ref={this.formWrap}>
          <div className={clsFormWrap}>
            <div className={clsForm}>
              <div className={clsHeadContent}>
                <h2>{stepHead[this.state.step].title}</h2>
                <div className="form_content_head_steps">
                  <span className='green'>Шаг&nbsp;</span>
                  <span className="green">{stepHead[this.state.step].number}&nbsp;</span>
                  <span>из 3</span>
                </div>
              </div>

              {
                (this.state.step === 0) &&
                <FormStepOne carMarks={this.state.carMarks}
                             changeBrand={this.handleChangeBrand}
                             formData={this.state.formData}
                             changeStep={this.handleChangeStep}
                             onSubmit={this.onSubmitStep}
                             getCarMarkById={this.getCarMarkById}
                             selectedBrand={this.state.selectedBrand}
                             changeModel={this.handleChangeModel}
                             carModels={this.state.carModels}
                             getCarModelById={this.getCarModelById}
                             selectedModel={this.state.selectedModel}
                             yearsList={this.yearsList}
                             showMotivate={this.state.showMotivate}
                             actionsMessage={this.state.actionsMessage}
                             selectedBrandName={this.state.selectedBrandName}
                             selectedModelName={this.state.selectedModelName}
                             activeYear={this.state.activeYear}
                />
              }
              {
                (this.state.step === 1) &&
                <FormStepTwo formData={this.state.formData}
                             changeStep={this.handleChangeStep}
                             onSubmit={this.onSubmitStep}
                             handleClearStepThree={this.handleClearStepThree}
                />
              }
              {
                (this.state.step === 2) &&
                <FormStepThree
                  regions={this.state.regions}
                  formData={this.state.formData}
                  changeStep={this.handleChangeStep}
                  onSubmit={this.onSubmitLastStep}
                  onSubmitStep={this.onSubmitStep}
                  countOffers={this.state.countOffers}
                  changeMobileClass={this.handleChangeMobileClass}
                  changeClsAnimate={this.changeClsAnimate}
                  handleChangeClsAnimate={this.handleChangeClsAnimate}
                  changeClsAnimateFullHeight={this.changeClsAnimateFullHeight}
                  changeClsAnimateFullSecondHeight={this.changeClsAnimateFullSecondHeight}
                  clearClsAnimate={this.clearClsAnimate}
                  changeClsStatic={this.changeClsStatic}
                  handleShowSecondContent={this.handleShowSecondContent}
                />
              }
              <Modal show={this.state.modalShow} hideModal={this.hideModal}/>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Form;
