import React, {Component} from 'react';
import Select from 'react-select'
import classNames from "classnames/bind";

class selectCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectListOpen: false,
      selectValue: '',
      inputIsFocus: false,
      disabled: true,
      isMobile: false
    };
    this.refSelectList = React.createRef();
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
    window.addEventListener('resize', () => {
      if (window.matchMedia("(max-width: 759px)").matches) {
        if (this.state.isMobile !== true) {
          this.setState({
            isMobile: true
          })
        }
      } else {
        if (this.state.isMobile !== false) {
          this.setState({
            isMobile: false
          })
        }
      }
    });
  }

  onMenuOpen = () => {
    this.setState({
      selectListOpen: true
    })
  };

  onMenuClose = () => {
    this.setState({
      selectListOpen: false
    })
  };

  onChangeSelect = (value) => {
    this.props.handleChange(value);
    this.setState({
      selectValue: value
    })
  };

  handleOnClick = (item) => {
    this.props.handleChange(item);
    this.setState({
      selectListOpen: false,
      inputIsFocus: true
    });
  };

  render() {
    const selectOpen = classNames('', {'select_open': this.state.selectListOpen || this.props.value !== ''});
    const cls = [
      'form_row_select_custom',
      this.props.cls,
      selectOpen
    ];

    let customStyles = {
      control: () => ({
        width: 100 + '%',
        height: 100 + '%'
      }),
      indicatorsContainer: () => ({ display: 'none' }),
      placeholder: () => ({ display: 'none' }),
      container: () => ({ height: 100 + '%' }),
      valueContainer: () => ({ height: 100 + '%' }),
      menu: () => ({
        background: '#f0f2f6',
        position: 'absolute',
        top: 94 + '%',
        left: 0,
        width: 100 + '%',
        zIndex: 10
      }),
      menuList: () => ({
        maxHeight: 360 + 'px',
        overflowY: 'auto',
        padding: 0,
        position: 'relative',
        webkitOverflowScrolling: 'touch'
      }),
      option: (styles, {  isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor:
            isSelected ? "#4AC054" : null,
          ':hover' : {
            backgroundColor: '#4AC054',
            color: '#fff'
          },
          ':active': {
            backgroundColor: '#4AC054'
          },
          padding: 16,
        };
      },
      input: () => ({
        height: 100 + '%',
        width: 100 + '%',
        paddingLeft: 20 + 'px',
        border: 'none',
        borderRadius: 10 + 'px',
        background: '#f0f2f6',
        color: '#4d4d59',
        fontWeight: 500,
        fontZize: 20 + 'px',
        fontFamily: 'Rubik,sans-serif',
        cursor: 'pointer'
      }),
      singleValue: () => ({
        color: '#4d4d59',
        overflow: 'hidden',
        position: 'absolute',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        top: 58 + '%',
        left: 20 + 'px',
        transform: 'translateY(' + -50 + '%)',
        fontWeight: 500,
        fontSize: 20 + 'px',
        lineHeight: 28 + 'px'
      })
    };

    if (window.matchMedia("(max-width: 759px)").matches || this.state.isMobile) {
      customStyles = {
        control: () => ({
          width: 100 + '%',
          height: 100 + '%'
        }),
        indicatorsContainer: () => ({ display: 'none' }),
        placeholder: () => ({ display: 'none' }),
        container: () => ({ height: 100 + '%' }),
        valueContainer: () => ({ height: 100 + '%' }),
        menu: () => ({
          background: '#f0f2f6',
          position: 'absolute',
          top: 94 + '%',
          left: 0,
          width: 100 + '%',
          zIndex: 10
        }),
        menuList: () => ({
          maxHeight: 360 + 'px',
          overflowY: 'auto',
          padding: 0,
          position: 'relative',
          webkitOverflowScrolling: 'touch'
        }),
        option: (styles, {  isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor:
              isSelected ? "#4AC054" : null,
            ':hover' : {
              backgroundColor: '#4AC054',
              color: '#fff'
            },
            ':active': {
              backgroundColor: '#4AC054'
            },
            padding: 16,
          };
        },
        input: () => ({
          height: 100 + '%',
          width: 100 + '%',
          paddingLeft: 14 + 'px',
          border: 'none',
          borderRadius: 10 + 'px',
          background: '#f0f2f6',
          color: '#4d4d59',
          fontWeight: 500,
          fontZize: 20 + 'px',
          fontFamily: 'Rubik,sans-serif',
          cursor: 'pointer'
        }),
        singleValue: () => ({
          color: '#4d4d59',
          overflow: 'hidden',
          position: 'absolute',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          top: 66 + '%',
          left: 14 + 'px',
          transform: 'translateY(' + -50 + '%)',
          fontWeight: 500,
          fontSize: 18 + 'px',
          lineHeight: 22 + 'px'
        })
      }
    }

    return (
      <div className={cls.join(' ')} ref={this.refSelectList} onClick={this.props.handleShowErrorMessage}>
        <label>{this.props.label}</label>
        <Select styles={customStyles}
                options={this.props.items}
                onMenuOpen={this.onMenuOpen}
                onMenuClose={this.onMenuClose}
                onChange={this.onChangeSelect}
                isDisabled={this.props.items.length <= 0}
                noOptionsMessage={() => 'Совпадений не найденно'}
                value={this.props.value}
        />
        <div className='error_text'>{this.props.showErrorMessage}</div>
      </div>
    )
  }
};

export default selectCustom;
