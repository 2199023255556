import React, { Component } from 'react';
import QuestionItem from '../../components/QuestionItem/QuestionItem';
import { questionsUrl } from '@app-constants/api';
import { sendGet } from '@app-services/ajax';
import QuestionImgWebp from '../../assets/images/dude.webp'
import QuestionImg from '../../assets/images/dude.png'

class Questions extends Component {
  state = {
    isOpened: null,
    questionItemsList: []
  };

  componentDidMount() {
    this.loadQuestions();
  }

  render() {
    const questionItems = this.state.questionItemsList.map(
      ({ number, question, answer, id }, index) => {
        return (
          <QuestionItem
            key={id}
            number={index}
            head={question}
            info={answer}
            id={id}
            handleChangeOpen={this.handleChangeOpen}
            isOpened={this.state.isOpened === id}
          />
        );
      }
    );

    return (
      <section className="section_questions container">
        <h1>{this.props.headerText.questions_header}</h1>
        <div className="question_list">
          {questionItems}
        <div className="question_list_img">
          <picture>
            <source srcSet={QuestionImgWebp} type="image/webp"/>
            <img src={QuestionImg} alt=""/>
          </picture>
        </div>
        </div>
      </section>
    );
  }

  async loadQuestions() {
    (async () => {
      let response;
      const url = questionsUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        questionItemsList: response.data
      });
    })();
  }

  handleChangeOpen = (id) => {
    if (this.state.isOpened !== id) {
      this.setState({isOpened: id});
    } else {
      this.setState({isOpened: null});
    }
  }
}

export default Questions;
