import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout';
import Header from './containers/Header/Header';
import Form from './containers/Form/Form';
import Feedback from './containers/Feedback/Feedback';
import Banks from './containers/Banks/Banks';
import Questions from './containers/Questions/Questions';
import Footer from "./containers/Footer/Footer";
import {headersUrl, regionIpUrl, phonesUrl} from "./constants/api";
import {sendGet} from "./services/ajax";
import lodashFind from 'lodash/find';
import {Helmet} from 'react-helmet';

class App extends Component {

  state = {
    headers: [],
    region: null,
    phone: ''
  };

  componentDidMount() {
    this.loadHeaders();
  }

  loadHeaders() {
    (async () => {
      let response, responseRegion, responsePhone;
      const url = headersUrl;
      const regionIp = regionIpUrl;
      const phone = phonesUrl;

      try {
        response = await sendGet(url);
        responseRegion = await sendGet(regionIp);
        responsePhone = await sendGet(phone);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        headers: response.data,
        region: Array.isArray(responseRegion.data) ? responseRegion.data[0] : responseRegion.data,
        phone: responsePhone.data === '' ? '' : responsePhone.data
      });
    })();
  }

  getHeadersByAliases = (aliases) => {
    const result = {};
    const headers = this.state.headers;
    aliases.forEach((item) => {
      const finded = lodashFind(headers, ['alias', item]);
      if (finded) {
        result[item] = finded.text;
      }
    });
    return result;
  };

  render() {
    if (!this.state.headers.length) {
      return null;
    }

    return (
      <Layout>
        <Helmet>
          <script type="text/javascript" >{`
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(56850232, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          });
          `}</script>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-155061629-1"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-155061629-1');
            gtag('config', 'AW-668997757');
          `}</script>
        </Helmet>
        <Header
          headerText={this.getHeadersByAliases(['header_text', 'sloan_step_one', 'sloan_step_two', 'sloan_step_three'])}
          phone={this.state.phone}
        />
        <Form
          headerText={this.getHeadersByAliases(['step_one_header',
            'step_two_header', 'step_three_header', 'sloan_step_one', 'sloan_step_two', 'sloan_step_three'])}
          regionIp={this.state.region}
        />
        <Feedback
          headerText={this.getHeadersByAliases(['feedback_header'])}
          regionIp={this.state.region}
        />
        <Banks
          headerText={this.getHeadersByAliases(['banks_header'])}
        />
        <Questions
          headerText={this.getHeadersByAliases(['questions_header'])}
        />
        <Footer
          headerText={this.getHeadersByAliases(['advice_header'])}
          phone={this.state.phone}
        />
      </Layout>
    );
  }
}

export default App;
