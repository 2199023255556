import React, {Component} from "react";

class Checkbox extends Component {
  state = {
    checked: true
  };

  handleCheckbox = () => {
    const newChecked = !this.state.checked;
    this.setState({checked: newChecked});
    this.props.isChecked(newChecked);
  };

  render() {

  return (
    <div className='form_row_checbox'>
      <label>
        <input
          className="checkbox"
          type="checkbox"
          checked={this.state.checked}
        />
        <span className="checkbox-custom" onClick={this.handleCheckbox}></span>
        <span className="checkbox-label">
          <a href="/media/policy.pdf" target="_blank">Согласен с условиями</a>
        </span>
      </label>
    </div>
  );
};

}
export default Checkbox;
