import React, {Component} from 'react';
import BankItem from '../../components/BankItem/BankItem';
import {banksUrl} from '@app-constants/api';
import {sendGet} from '@app-services/ajax';
// eslint-disable-next-line
var yall = require('script-loader!yall-js/dist/yall.min.js');

class Banks extends Component {
  state = {
    banksList: []
  };

  componentDidMount() {
    this.setState({banksList: this.renderListBanks()});
  }

  componentDidUpdate() {
    if (Array.isArray(this.state.banksList) && this.state.banksList.length) {
      window.yall();
    }
  }

  renderListBanks() {
    (async () => {
      let response;
      const url = banksUrl;

      try {
        response = await sendGet(url);
      } catch (err) {
        throw new Error(err);
      }

      this.setState({
        banksList: response.data
      });
    })();
  }

  render() {
    return (
      <section className="section_banks container">
        <h1>{this.props.headerText.banks_header}</h1>
        {this.renderList()}
      </section>
    )
  }

  renderList() {
    const bankItem = (this.state.banksList || []).map(({image, name, bank_class}, index) => {
      return (
        <BankItem key={index} icon={image} name={name} bank_class={bank_class}/>
      );
    });

    if (bankItem.length > 0) {
      return (
        <div className="banks_wrapper">
          <div className="banks_wrap">
            {bankItem}
          </div>
        </div>
      );
    }
    return <div/>;
  }

}

export default Banks;
