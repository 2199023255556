import React from 'react';
import classNames from 'classnames/bind';

const YearItem = props => {
  const cls = classNames( 'form_content_years_item', {'active': props.active});
  return (
      <div className={cls} onClick={() => props.activeYear(props.year)}>{props.year}</div>
  );
};

export default YearItem;