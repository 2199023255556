import {withFormsy} from 'formsy-react';
import React from 'react';

const FormFieldError = ({errorMessage, className}) => {
  if (typeof errorMessage === 'string' && errorMessage.length > 0) {
    return (
      <div className={['error_text show_error', className].join(' ')} dangerouslySetInnerHTML={{__html: errorMessage}}/>
    );
  }
  return null;
};

class InputString extends React.Component {
  constructor(props) {
    super(props);
    this.isChanged = false;
  }

  componentDidMount() {
    this.props.setValue(this.props.defaultValue);
  }

  handleOnChange = (event) => {
    let newValue = event.currentTarget.value.trimLeft();
    if (typeof this.props.changeValueFio === 'function') {
      this.props.changeValueFio(newValue);
    }
    if (this.props.formatter) {
      newValue = newValue.replace(this.props.formatter, '');
    }
    if (newValue.length > 2) {
      this.isChanged = true;
    }
    this.props.setValue(newValue);
  };

  render() {
    let className = 'form_row row_white';
    if (
      ((this.isChanged && this.props.isFormSubmitted()) || this.props.isFormSubmitted())
      && !this.props.isValid()
    ) {
      className = [className, 'error'].join(' ');
    } else if (
      ((this.isChanged && this.props.isFormSubmitted()) || this.props.isFormSubmitted())
      && this.props.isValid()
    ) {
      className = [className, 'valid'].join(' ');
    }
    const value = this.props.getValue() || '';
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={className}>
        <div >
          <input type="text" onChange={this.handleOnChange} placeholder="&nbsp;" value={value}/>
          <label>{this.props.label}</label>
        </div>
        {this.props.isFormSubmitted() ? <FormFieldError errorMessage={errorMessage} /> : null}
      </div>

    );
  }
}

export default withFormsy(InputString);
